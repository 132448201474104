import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const schema = z.object({
  candidate: z.string().optional().catch(undefined),
  from: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_private/_route/_internal/peers/new/')({
  validateSearch: schema,
});
