import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import { getCandidateV2Options } from 'client/@tanstack/react-query.gen';
import type { CandidateInternalFullResponse } from 'client/types.gen';
import { CandidateType, Function, Industry } from 'client/types.gen';
import { zFiltersArray } from 'helpers/router';
import { dateRangeIntervalSchema, objToEnum } from 'helpers/zod';

const schema = z
  .object({
    advisor_id: zFiltersArray(),
    candidate_id: z.string().optional(),
    function_expertise: zFiltersArray(z.enum(objToEnum(Function))),
    in_the_red_advisor_id: zFiltersArray(),
    industry_expertise: zFiltersArray(z.enum(objToEnum(Industry))),
    interval: dateRangeIntervalSchema,
    peer_id: z.string().optional(),
    period: z
      .union([z.literal('upcoming'), z.literal('recent')])
      .optional()
      .default('upcoming'),
    program: zFiltersArray(z.enum(objToEnum(CandidateType))),
  })
  .partial({
    advisor_id: true,
    candidate_id: true,
    in_the_red_advisor_id: true,
    peer_id: true,
  })
  .optional()
  .default({
    interval: '3mo',
    period: 'upcoming',
  })
  .catch({
    interval: '3mo',
    period: 'upcoming',
  });

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute('/_private/_route/_internal/performance/')(
  {
    beforeLoad: async ({ context, search }) => {
      if (!posthog.isFeatureEnabled('project-client-performance')) {
        throw redirect({ replace: true, to: '/' });
      }
      const { queryClient } = context;
      const { candidate_id, peer_id } = search;
      const response: {
        candidate?: CandidateInternalFullResponse;
        peer?: CandidateInternalFullResponse;
      } = { candidate: undefined, peer: undefined };
      if (candidate_id) {
        const candidate = await queryClient.ensureQueryData({
          ...getCandidateV2Options({
            path: {
              candidate_uuid: candidate_id,
            },
          }),
        });
        response.candidate = candidate;
      }

      if (peer_id) {
        const peer = await queryClient.ensureQueryData({
          ...getCandidateV2Options({
            path: {
              candidate_uuid: peer_id,
            },
          }),
        });
        response.peer = peer;
      }
      return response;
    },
    loaderDeps: ({
      search: {
        advisor_id,
        candidate_id,
        function_expertise,
        in_the_red_advisor_id,
        industry_expertise,
        peer_id,
        period,
        program,
      },
    }) => ({
      advisor_id,
      candidate_id,
      function_expertise,
      in_the_red_advisor_id,
      industry_expertise,
      peer_id,
      period,
      program,
    }),
    validateSearch: schema,
  },
);
