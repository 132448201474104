import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import { getCandidateV2Options } from 'client/@tanstack/react-query.gen';
import { CandidateType, Function, Industry } from 'client/types.gen';
import { zFiltersArray } from 'helpers/router';
import { dateRangeIntervalSchema, objToEnum } from 'helpers/zod';

const schema = z.object({
  function_expertise: zFiltersArray(z.enum(objToEnum(Function))),
  industry_expertise: zFiltersArray(z.enum(objToEnum(Industry))),
  modal: z.literal('p2p').optional(),
  peer: z.string().optional(),
  period: dateRangeIntervalSchema,
  program: zFiltersArray(z.enum(objToEnum(CandidateType))),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/compare/',
)({
  validateSearch: schema,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loaderDeps: ({
    search: {
      function_expertise,
      industry_expertise,
      modal,
      peer,
      period,
      program,
    },
  }) => ({
    function_expertise,
    industry_expertise,
    modal,
    peer,
    period,
    program,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  beforeLoad: async ({ context, search }) => {
    if (!posthog.isFeatureEnabled('project-client-performance')) {
      throw redirect({ replace: true, to: '/' });
    }

    const { queryClient } = context;

    if (search.peer) {
      const pPeer = await queryClient.ensureQueryData({
        ...getCandidateV2Options({
          path: {
            candidate_uuid: search.peer,
          },
        }),
      });
      return { peer: pPeer };
    }

    return {};
  },
});
